import Vue from 'vue';
import App from './App.vue';
import '@/assets/scss/app.scss';
import Buefy from 'buefy';
Vue.use(Buefy);

import AOS from 'aos';
import 'aos/dist/aos.css';
AOS.init({
	once: true,
});

var VueScrollTo = require('vue-scrollto');
 
Vue.use(VueScrollTo)

Vue.config.productionTip = false;

new Vue({
	render: (h) => h(App),
}).$mount('#app');
