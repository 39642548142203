<template>
	<nav class="navbar is-fixed-top is-primary" role="navigation" aria-label="main navigation">
		<div class="container">
			<div class="navbar-brand">
				<a href="#home" v-scroll-to="{ el: '#home', offset: -200 }" class="navbar-item">
					<img src="@/assets/svg/logo-text-white.png" />
				</a>

				<a
					role="button"
					class="navbar-burger"
					aria-label="menu"
					:class="isMenuOpen && 'is-active'"
					aria-expanded="false"
					@click="isMenuOpen = !isMenuOpen"
					data-target="navbarBasicExample"
				>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
					<span aria-hidden="true"></span>
				</a>
			</div>

			<div id="navbarBasicExample" class="navbar-menu has-background-primary" :class="isMenuOpen && 'is-active'">
				<div class="navbar-start">
					<a href="#solution" v-scroll-to="{ el: '#solution', offset: -200 }" class="navbar-item" :class="isMobile && 'has-text-white'"> A Solução </a>
					<a href="#process" v-scroll-to="{ el: '#process', offset: -50 }" class="navbar-item" :class="isMobile && 'has-text-white'"> O Processo </a>
					<a href="#benefits" v-scroll-to="{ el: '#benefits' }" class="navbar-item" :class="isMobile && 'has-text-white'"> Vantagens </a>
					<a href="#features" v-scroll-to="{ el: '#features', offset: -100 }" class="navbar-item" :class="isMobile && 'has-text-white'"> Funcionalidades </a>
					<a href="#integrations" v-scroll-to="{ el: '#integrations', offset: -200 }" class="navbar-item" :class="isMobile && 'has-text-white'"> Integrações </a>
					<a href="#faq" v-scroll-to="{ el: '#faq', offset: -50 }" class="navbar-item" :class="isMobile && 'has-text-white'"> FAQ </a>
				</div>

				<div class="navbar-end">
					<div class="navbar-item pr-6">
						<div class="buttons" :class="isMobile && 'is-justify-content-center'">
							<a href="#contacts" v-scroll-to="{ el: '#contacts', offset: 0 }">
								<b-button type="is-white" class="has-text-primary has-text-weight-semibold p-5">Fale connosco</b-button>
							</a>
						</div>
						<!-- <div class="navbar-item">
						<figure class="image"><img src="@/assets/svg/premio.svg" alt="" /></figure>
					</div> -->
					</div>
				</div>
			</div>
		</div>
	</nav>
</template>

<script>
	export default {
		name: 'Navbar',
		data() {
			return {
				isMenuOpen: false,
			};
		},
		computed: {
			isMobile() {
				return window.innerWidth <= 1024;
			},
		},
	};
</script>

<style scoped lang="scss">
	.navbar-item,
	.navbar-link {
		color: #fff;
	}
</style>
