<template>
	<b-collapse class="faq-collapse my-4" animation="slide" :open="open" @open="$emit('open')">
		<template #trigger="props">
			<div :class="{ 'is-open': props.open }" class="faq-collapse-trigger is-flex is-justify-content-space-between is-align-items-center p-4 has-text-weight-semibold">
				<p>
					{{ title }}
				</p>
				<a :class="{ 'has-text-dark': !props.open, 'has-text-primary': props.open }">
					<b-icon size="is-medium" :icon="props.open ? 'close-circle-outline' : 'chevron-down'"> </b-icon>
				</a>
			</div>
		</template>
		<div class="faq-collapse-body p-4">
			<div class="has-text-weight-medium" v-html="text"></div>
		</div>
	</b-collapse>
</template>

<script>
	export default {
		name: 'Collapse',
		props: {
			open: {
				type: Boolean,
				default: false,
			},
			title: {
				type: String,
				default: '',
			},
			text: {
				type: String,
				default: '',
			},
		},
	};
</script>

<style lang="scss" scoped>
	@import '@/assets/scss/_variables.scss';
	.faq-collapse {
		.faq-collapse-trigger {
			border-radius: 1rem;
			border: 2px solid $primary;
			&.is-open {
				background-color: rgba(26, 141, 55, 0.05);
				border: unset;
				border-radius: 1rem 1rem 0 0;
			}
		}
		.faq-collapse-body {
			background-color: rgba(26, 141, 55, 0.05);
			border-radius: 0 0 1rem 1rem;
		}
	}
</style>
