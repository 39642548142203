<template>
	<footer class="footer has-background-primary">
		<div class="columns is-mobile is-multiline is-vcentered">
			<div class="column is-3-desktop is-6-touch">
				<figure class="image"><img src="@/assets/svg/iotech.png" alt="" /></figure>
			</div>
			<div class="column is-3-desktop is-6-touch">
				<p class="has-text-white has-text-centered">© IOTech {{ currentYear }}, Todos os direitos reservados</p>
			</div>
			<div class="column is-3-desktop is-6-touch">
				<a class="has-text-white has-text-centered" target="__blank" href="https://docs.iotech.pt/IOTech/iotech_terms_consent_pt-PT.pdf">Termos de Consentimento</a>
			</div>
			<div class="column is-3-desktop is-6-touch">
				<a class="has-text-white has-text-centered" target="__blank" href="https://docs.iotech.pt/IOTech/iotech_privacy_policy_pt-PT.pdf">Política de Privacidade</a>
			</div>
		</div>
	</footer>
</template>

<script>
	export default {
		name: 'Footer',
		data() {
			return {
				currentYear: new Date().getFullYear(),
			};
		},
	};
</script>

<style></style>
